<template>
  <div class="varietyDetail">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <div class="msgWrap">
      <div class="msg">
        <div class="name">{{ detail.breedName }}</div>
        <span>品种权号：{{ detail.ownerNo }}</span>
        <span>授权日：{{ detail.grantDate }}</span>
      </div>
    </div>
    <div class="tableWrap">
      <div class="titleWrap">
        <div class="title">品种授权信息</div>
      </div>
      <div class="contentWrap">
        <div class="table">
          <el-row type="flex">
            <el-col :span="3">品种授权人</el-col>
            <el-col :span="9">{{ detail.owner || '-' }}</el-col>
            <el-col :span="3">公共品种授权人</el-col>
            <el-col :span="9">{{ detail.commonOwner || '-' }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="3">培育人</el-col>
            <el-col :span="21">{{ detail.breeders || '-' }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="3">品种权人地址</el-col>
            <el-col :span="21">{{ detail.ownerAddress || '-' }}</el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="tableWrap footer">
      <div class="titleWrap">
        <div class="title">号码及日期</div>
      </div>
      <div class="contentWrap">
        <div class="table">
          <el-row type="flex">
            <el-col :span="3">申请号</el-col>
            <el-col :span="9">{{ detail.appCode || '-' }}</el-col>
            <el-col :span="3">公告号</el-col>
            <el-col :span="9">{{ detail.pubNo || '-' }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="3">公告期号</el-col>
            <el-col :span="21">{{ detail.issueNo || '-' }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="3">授权日</el-col>
            <el-col :span="21">{{ (detail.grantDate && detail.grantDate.split(' ')[0]) || '-' }}</el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import { Message } from 'element-ui';

export default {
  name: 'varietyDetail',
  data() {
    return {
      breadList: [{ name: '热点功能' }, { name: '品种权检索', path: '/varietyList' }, { name: '详情' }],
      detail: {} // 品种权详情
    };
  },
  methods: {
    async getDetail() {
      const id = this.$route.query.id;
      const api = this.$fetchApi.varietyDetail.api + id;
      const data = await this.$fetchData.fetchPost({}, api);
      if (data.code === '200') {
        this.detail = data.result;
      } else {
        Message.error(data.message);
      }
    }
  },
  created() {
    this.getDetail();
  },
  components: { nfBreadcrumb }
};
</script>

<style lang="less" scoped>
.msgWrap {
  height: 142px;
  background: #f3f7fb;
  .msg {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 35px;
    padding-left: 40px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 6px;
      height: 12px;
      border-radius: 3px;
      background: #265ae1;
      left: 20px;
      top: 43px;
    }
    .name {
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 20px;
    }
    span {
      font-size: 16px;
      color: #666;
      padding-right: 40px;
    }
  }
}

.tableWrap {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  border: 1px solid #f5f5f5;
  box-sizing: border-box;
  .titleWrap {
    background: #fafafa;
    height: 38px;
    .title {
      width: 150px;
      height: 100%;
      background: #fff;
      box-sizing: border-box;
      border-top: 2px solid #448afc;
      font-size: 16px;
      text-align: center;
      line-height: 38px;
    }
  }
  .contentWrap {
    width: 1180px;
    margin: 10px;
    .table {
      .el-row {
        .el-col {
          border: 1px solid #eee;
          padding: 15px 10px;
        }
        .el-col:nth-child(odd) {
          background: #f5f5f5;
          text-align: center;
        }
      }
    }
  }
}
.footer {
  margin-bottom: 40px;
}
</style>
